import { graphql } from "gatsby"
import Img from "gatsby-image"
import React, { useRef, forwardRef, useEffect } from "react"
import Header from "../components/header"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Vimeo from "@u-wave/react-vimeo"
import ReactPlayer from "react-player"
import parse from "html-react-parser"
import { ScrollTrigger } from "gsap/ScrollTrigger.js"
import { gsap, CustomEase } from "gsap"

const PostHeading = ({ children }) => {
  return (
    <h1 className="text-blue font-['SharpSemiBold20'] text-[18px] mobile:text-[24px] tablet:text-[32px] laptop:text-[42px] mb-[20px] tablet:mb-[10px]  leading-none tablet:leading-[32px] large:leading-[42px] mt-[-2px]">
      {children}
    </h1>
  )
}

const Heading = ({ children }) => {
  return (
    <h1 className="text-blue font-['SharpSemiBold'] text-[18px] mobile:text-[21px] tablet:text-[28px] laptop:text-[42px] mb-[10px] tablet:mb-[20px] laptop:mb-[33px] leading-none tablet:leading-[32px] large:leading-[42px] mt-[-15px] mobile:mt-[2px]">
      {children}
    </h1>
  )
}

const Content = ({ children }) => {
  return (
    <div className="text-blue font-['SharpBook'] font-normal text-[14px] tablet:text-[19px] laptop:text-[28px] large:text-[40px] leading-tight post-info mb-[20px] mobile:mb-[0px]">
      {children}
    </div>
  )
}

const BulletHeading = ({ children }) => {
  return (
    <h1 className="text-blue font-['SharpSemiBold'] text-[19px] tablet:text-[20px] laptop:text-[29px] large:text-[41px] mb-[10px] tablet:mb-[20px] leading-none">
      {children}
    </h1>
  )
}

const Bullet = ({ children }) => {
  return (
    <h3 className="text-blue font-['SharpBook'] text-[14px] tablet:text-[19px] laptop:text-[28px] large:text-[40px] leading-tight post-info">
      {children}
    </h3>
  )
}

const Container = ({ children }) => {
  return (
    <div className="pt-[90px] mobile:pt-[58px] tablet:pt-[86px] laptop:pt-[50px] pb-[22px] px-[22px] mobile:pb-[20px] mobile:px-[20px] tablet:pb-[30px] tablet:px-[30px]">
      <Header />
      {children}
    </div>
  )
}

const Scope = ({ scope }) => {
  return (
    scope.length && (
      <div>
        <BulletHeading>Scope</BulletHeading>

        {scope.map(({ scopeLine }, index) => (
          <Bullet>{scopeLine}</Bullet>
        ))}
      </div>
    )
  )
}

const Information = forwardRef(({ title, postDetails }, ref) => {
  const { agency, descriptionTitle, description, scope } = postDetails
  return (
    <div
      ref={ref}
      className={`pt-[45px] pb-[30px] tablet:pt-[35px] laptop:pt-[90px] grid grid-cols-12 mobile:grid-cols-12 absolute z-[100] bg-white border-b-blue border-b-2 mr-[22px] mobile:mr-[20px] tablet:mr-[30px] `}
    >
      <div className="col-start-1 col-end-5 flex flex-col justify-between ">
        <PostHeading>{title}</PostHeading>
        <div className="flex flex-row">
          <div className="hidden mobile:flex flex-col flex-1">
            <Scope scope={scope} />
          </div>
          {agency && (
            <div className="hidden mobile:flex flex-1 flex-col justify-start">
              <BulletHeading>Agency</BulletHeading>
              <Bullet>{agency}</Bullet>
            </div>
          )}
        </div>
      </div>

      <div className="col-start-1 mobile:col-start-5 laptop:col-start-7 col-end-13">
        <Heading>{descriptionTitle}</Heading>
        <Content>{parse(description)}</Content>
        <div className="flex mobile:hidden">
          <Scope scope={scope} />
          <Bullet>{agency}</Bullet>
        </div>
      </div>
    </div>
  )
})

const BlogPostTemplate = ({ data: { post } }) => {
  const elem = useRef(null)
  const pinElem = useRef(null)

  gsap.registerPlugin(ScrollTrigger)

  useEffect(() => {
    if (!post?.postDetails?.description) return

    ScrollTrigger.normalizeScroll(true)

    gsap.to(pinElem.current, {
      scrollTrigger: {
        trigger: pinElem.current,
        scrub: true,
        markers: false,
        pin: elem.current,
        start: 0,
        end: "bottom 0%",
      },
    })
  }, [post?.postDetails?.description])

  return (
    <Layout>
      <Seo title={post.title} description={post.excerpt} />
      <Container>
        {post?.postDetails?.description && (
          <Information
            ref={pinElem}
            title={post.title}
            postDetails={post.postDetails}
          />
        )}
        <div className="blog-post" ref={elem}>
          {post?.imageGallery?.images?.map((item, index) => {
            if (item?.rowType === "single") {
              return (
                <div
                  key={index}
                  className={`mt-[22px] laptop:mt-[30px] ${
                    index === 0 && "mt-[12px] tablet:!mt-[5px] laptop:!mt-[-4px]"
                  }`}
                >
                  <Img
                    fluid={item?.image1?.localFile?.childImageSharp?.fluid}
                  />
                </div>
              )
            } else if (item?.rowType === "video") {
              return (
                <div
                  key={index}
                  className={`relative pb-[56.25%] mt-[22px] laptop:mt-[30px] ${
                    index === 0 && "!mt-[12px] tablet:!mt-[5px]"
                  }`}
                >
                  <ReactPlayer
                    url={item?.vimeoId}
                    playsinline
                    playing
                    muted
                    loop
                    width="100%"
                    height="100%"
                    className="top-0 left-0 absolute"
                  />
                </div>
              )
            } else {
              return (
                <div
                  key={index}
                  className={`flex-col mobile:flex-row flex w-[100%] mt-[22px] laptop:mt-[30px] gap-[20px] tablet:gap-[22px] laptop:gap-[30px] large:gap-[30px] ${
                    index === 0 && "tablet:mt-[11px]"
                  }`}
                >
                  <Img
                    fluid={item?.image1?.localFile?.childImageSharp?.fluid}
                    className="w-[100%] mobile:w-[50%]"
                  />
                  <Img
                    fluid={item?.image2?.localFile?.childImageSharp?.fluid}
                    className="w-[100%] mobile:w-[50%]"
                  />
                </div>
              )
            }
          })}
          {/* 
        {!!post.content && (
          <section itemProp="articleBody">{parse(post.content)}</section>
        )} */}
        </div>
      </Container>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
      postDetails {
        description
        descriptionTitle
        category
        agency
        scope {
          scopeLine
        }
      }
      imageGallery {
        images {
          rowType
          vimeoId
          image1 {
            localFile {
              childImageSharp {
                fluid(maxWidth: 2400, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          image2 {
            localFile {
              childImageSharp {
                fluid(maxWidth: 2400, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
